@import '../../styles/variables.module.scss';
@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';

$slider-button-width: 40px;
$slider-button-height: 40px;
$slideshow-drag-indicator-size-lg: 200px;
$slideshow-drag-indicator-size-md: 140px;
$slideshow-drag-indicator-size-sm: 100px;

.slider-container {
  cursor: grab;
  margin: 0 -20px;
  overflow: visible;
}

.slideshow {
  margin: 0 -10px;
  position: relative;

  @include medium {
    margin: 0 -20px;
  }

  &__indicator {
    width: $slideshow-drag-indicator-size-sm;
    height: $slideshow-drag-indicator-size-sm;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba($color-black, 0.7);
    backdrop-filter: blur(10px);
    border-radius: calc(#{$slideshow-drag-indicator-size-sm} / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $z-index-slider-indicator;
    pointer-events: none;
    opacity: 0;
    transition: opacity $ease-page-transition;
    will-change: opacity;

    @include medium {
      width: $slideshow-drag-indicator-size-md;
      height: $slideshow-drag-indicator-size-md;
      border-radius: calc(#{$slideshow-drag-indicator-size-lg} / 2);
    }

    @include xxx-large {
      width: $slideshow-drag-indicator-size-lg;
      height: $slideshow-drag-indicator-size-lg;
    }

    &--active {
      opacity: 1;
    }
  }
}

.slide {
  & > :first-child {
    border-radius: 0 0 $border-radius-sm $border-radius-sm;

    @include small {
      border-radius: 0 0 $border-radius-md $border-radius-md;
    }
  }

  &--rounded {
    & > :first-child {
      border-radius: $border-radius-md;
    }
  }

  &__title {
    padding: gap(1);

    @include medium {
      padding: gap(2);
    }
  }
}

.slider {
  &__navigation {
    position: absolute;
    right: gap(4);
    top: gap(4);
    z-index: $z-index-main-content;

    &__prev,
    &__next {
      width: $slider-button-width;
      height: $slider-button-height;
      background-color: $color-black;
      z-index: $z-index-main-content;
    }

    &__next {
      margin-top: gap(2);
    }
  }
}
