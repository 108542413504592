@import './variables.module.scss';

// only use for images
.m-t-image {
  margin-top: gap(1);

  @include medium {
    margin-top: gap(1);
  }

  &--sm {
    margin-top: gap(1);

    @include medium {
      margin-top: 0;
    }
  }

  &--md {
    margin-top: gap(1);

    @include medium {
      margin-top: gap(1);
    }

    @include large {
      margin-top: 0;
    }
  }
}

// use for elements/divs when align
// underneath depending on breakpoint
.m-t {
  margin-top: $section-gap;

  &--sm {
    margin-top: $section-gap;

    @include medium {
      margin-top: 0;
    }
  }

  &--md {
    margin-top: $section-gap;

    @include large {
      margin-top: 0;
    }
  }
}

.m {
  &-r {
    &-0 {
      margin-right: 0;

      &-md {
        margin-right: 0 !important;

        @include medium {
          margin-right: unset;
        }
      }
    }
  }

  &-t {
    &-1 {
      margin-top: 1em;

      &--sm {
        margin-top: 1em;

        @include medium {
          margin-top: unset;
        }
      }
    }
  }
}

.flex {
  display: flex;

  &--align-end {
    align-items: flex-end;
  }
  &--align-center {
    align-items: center;
  }
  &--align-start {
    align-items: flex-start;
  }
  &--justify-end {
    justify-content: flex-end;
  }
  &--justify-center {
    justify-content: center;
  }
  &--justify-start {
    justify-content: flex-start;
  }
}
