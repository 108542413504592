@import '../variables.module.scss';
@import '../mixins.scss';

.button {
  padding: 0 gap(2);
  display: inline-flex;
  height: $button-height-sm;
  line-height: $button-height-sm;
  background-color: $color-white;
  border-radius: $border-radius-md;
  font-size: $font-size-sm;
  border: 1px solid $color-white;
  transition: all $ease-nav-item;
  font-family: $font-family-bold;
  align-items: center;

  @include large {
    height: $button-height-lg;
    line-height: $button-height-lg;
  }

  &:hover {
    background-color: $color-black;
    color: $color-white;
    border-color: $color-black;
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-right: gap(1);
  }

  &--filled-white {
    background-color: $color-white;
    color: $color-black;
  }

  &--filled-dark {
    background-color: $color-black;
    color: $color-white;
    border-color: $color-black;
  }

  &--filled-transparent {
    background-color: transparent;
    color: $color-black;
    border-color: $color-black;
  }

  &--outline-dark {
    border: 1px solid $color-black;
    background-color: transparent;

    @include large {
      border: 1px solid $color-black !important;
      background-color: transparent !important;
    }

    &:hover {
      color: $color-white !important;
      background-color: $color-black !important;
      border-color: $color-black !important;
    }
  }

  &--outline-light {
    color: $color-white;
    border: 1px solid $color-white;
    background-color: transparent;

    @include large {
      color: $color-white !important;
      border: 1px solid $color-white !important;
      background-color: transparent !important;
    }

    &:hover {
      color: $color-black !important;
      background-color: $color-white !important;
      border-color: $color-white !important;
    }
  }

  &--dropdown {
    width: 100%;
    text-align: left;
    position: relative;

    // arrow
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: gap(2);
      width: 0;
      height: 0;
      top: calc(50% - 3px);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid $color-white;
    }
  }
}
