@import './mixins.scss';
@import url('https://fast.fonts.net/lt/1.css?apiType=css&c=1249723b-b0c9-47b4-b690-542d0938718c&fontids=6296918');

@font-face {
  font-family: 'Futura LT W05 Extra Bold';
  src: url('../fonts/920ace4f-b33c-4f86-b286-f04f2d07030d.woff2')
      format('woff2'),
    url('../fonts/8f354944-62ed-4c3e-b1bc-484b277845a2.woff') format('woff');
}
@font-face {
  font-family: 'SuisseIntl Bold';
  src: url('../fonts/SuisseIntl-Bold-WebM.woff2') format('woff2'),
    url('../fonts/SuisseIntl-Bold-WebM.woff') format('woff');
}
@font-face {
  font-family: 'SuisseIntl Regular';
  src: url('../fonts/SuisseIntl-Regular-WebM.woff2') format('woff2'),
    url('../fonts/SuisseIntl-Regular-WebM.woff') format('woff');
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-family: $font-family-regular;
}

h1,
h2,
h3,
h4 {
  margin: 0 0 0.5em;
  font-weight: 400;
  hyphens: none;
}

p {
  margin: 0 0 1em 0;

  &:last-child {
    margin: 0;
  }
}

p,
a,
li,
ol,
span,
strong {
  @include font-body;
  font-family: $font-family-regular;
  font-size: $font-size-md;
  letter-spacing: 0;
}

.font {
  &--primary {
    font-family: $font-family-headline;
    letter-spacing: -0.04em;
  }
  &--secondary {
    font-family: $font-family-regular;
  }
  &--bold {
    font-family: $font-family-bold;
  }
  &--xs {
    font-size: $font-size-xs;
  }
  &--light {
    color: $color-beige;
  }
  &--sm {
    @include font-sm;
  }
  &--md {
    @include font-md;
  }
  &--lg {
    @include font-lg;
  }
  &--xl {
    @include font-xl;
  }
  &--xxl {
    @include font-xxl;
  }
}

strong,
strong a {
  font-family: $font-family-bold;
}

.main {
  &--legal {
    h2 {
      @include font-lg;
      font-family: $font-family-bold;
    }
  }
}

.letter-spacing {
  &-none {
    letter-spacing: 0;
  }
}
