// gap used for spacing
$gap: 10px;
@function gap($factor) {
  @return $gap * $factor;
}

$color-white: white;
$color-grey-lightest: #f2f2f2;
$color-black: black;
$color-grey-dark: #333;
$color-grey-light: #646464;
$color-grey-lighter: #ddd;
$color-light-peach: #eecba9;
$color-green-darker: #457437;
$color-green-dark: #41ad49;
$color-green-light: #e8efdd;

$color-blue-dark: #b7c9cc;
$color-blue-primary: #b6d9e2;
$color-blue-light: #e9eeef;

$color-brown: #eecba9;
$color-brown-light: #fdf4f2;

$color-orange: #eecba9;
$color-orange-light: #f9e0cc;

$color-beige: #fdf4f2;
$color-violet: #dadfef;
$color-yellow: #fdf2c9;
$color-pink: #ffb2a3;
$color-pink-light: #ffc6a9;
$color-green: #e5efdf;
$color-error: #ff0055;

:export {
  colorPinkPrimary: $color-pink;
  colorPinkPrimaryLight: $color-pink-light;
  colorBluePrimary: $color-blue-primary;
  colorBlueDark: $color-blue-dark;
  colorWhite: $color-white;
  colorOrangeLight: $color-orange-light;
  colorOrange: $color-orange;
  colorBrown: $color-brown;
  colorGreenLight: $color-green-light;
  colorViolet: $color-violet;
  colorYellow: $color-yellow;
  colorLightGrey: $color-grey-lighter;
  colorBlack: $color-black;
  colorLightPeach: $color-light-peach;
}

$offset-sm: -20px;
$offset-md: -30px;

// ease
$ease-cubic: 0.7s cubic-bezier(0.8, 0, 0.2, 1) 0s;

// $border-radius
$border-radius-sm: 10px;
$border-radius-md: 25px;
$border-radius-lg: 40px;

// header
$header-height: 100px;
$burger-size: 50px;

// navigation
$transition-delays: (
  0.6s,
  0.65s,
  0.7s,
  0.75s,
  0.8s,
  // 5
  0.85s,
  0.9s,
  0.95s,
  1s,
  1.05s,
  // 10
  1.1s,
  1.15s,
  1.2s
);

// buttons
$button-height-sm: 40px;
$button-height-lg: 50px;
$filter-height: $button-height-sm;

// buttons - accessibility
$accessibility-btn-size-sm: 30px; // accessiBe sizes
$accessibility-btn-size-lg: 45px; // accessiBe sizes

// buttons - social-media
$socials-btn-size-sm: $button-height-sm;
$socials-btn-size-lg: $button-height-lg;

// buttons - music player
$music-player-size-sm: 30px; // accessiBe sizes
$music-player-size-lg: 45px; // accessiBe sizes

// buttons - mapbox controls
$mapbox-controls-size-sm: $button-height-sm;
$mapbox-controls-size-lg: $button-height-lg;

// buttons - swiper slider
$control-button-size: $button-height-sm;

// icons
$icon-size-sm: 50px;
$icon-size-md: 68px;
$icon-size-lg: 90px;

// shadow
$shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2);

// sound
$bar-size-sm: 5px;
$bar-size-lg: 7px;

// mapbox filter
$max-width-filter: 500px;

// z-index
$z-index-main-content: 10;
$z-index-accessibility-button: 150;
$z-index-notification: 500;
$z-index-music-player: 150;
$z-index-mapbox-filter-container: 4;
$z-index-mapbox-selected-pin: 1;
$z-index-mapbox-controls: 3;
$z-index-mapbox-pin-detail-overlay: 4;
$z-index-preloader: 1000;
$z-index-cookie: 200;
$z-index-slider-indicator: 100;
$z-index-emphasize: 100;
$z-index-footer: 160;

// ease
$ease-cubic: 0.7s cubic-bezier(0.7, 0, 0.3, 1);
$ease-link: 0.3s ease;
$ease-page-transition: 0.5s ease-in-out;
$ease-nav-item: 0.4s ease-out;

// font family
$font-family-regular: 'SuisseIntl Regular', sans-serif;
$font-family-bold: 'SuisseIntl Bold', sans-serif;
$font-family-headline: 'Futura LT W05 Extra Bold';

// font sizes
$font-size-6xl: calc(100px + 0.1vw);
$font-size-5xl: calc(86px + 0.1vw);
$font-size-4xl: calc(72px + 0.1vw);
$font-size-3xl: calc(56px + 0.1vw);
$font-size-xxl: calc(32px + 0.1vw);
$font-size-xl: calc(21px + 0.1vw);
$font-size-lg: calc(18px + 0.1vw);
$font-size-md: calc(15px + 0.1vw);
$font-size-sm: 14px;
$font-size-xs: 13px;
$font-size-xxs: 11px;
$font-size-3xs: 10px;

// breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  3xl: 1800px,
);

// spaces & gaps
$section-gap: calc(40px + 1vw);
$section-gap-md: calc(150px + 3vw);
$section-gap-lg: calc(200px + 1vw);

// gutter
$bsGutter: var(--bs-gutter-x);
