@import '../../styles/variables.module.scss';
@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';

.music-player,
.speaker {
  position: fixed;
  z-index: $z-index-music-player;
  cursor: pointer;
  left: gap(2);
  width: $music-player-size-sm;
  height: $music-player-size-sm;
  border: 1px solid $color-black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: border-color $ease-cubic;

  @include medium {
    left: gap(3);
  }

  @include large {
    width: $music-player-size-lg;
    height: $music-player-size-lg;
  }
}

.music-player {
  bottom: calc(gap(3) + $music-player-size-sm);
  overflow: hidden;

  @include medium {
    bottom: calc(gap(4) + $music-player-size-sm);
  }

  @include large {
    width: $music-player-size-lg;
    height: $music-player-size-lg;
    bottom: calc(gap(4) + $music-player-size-lg);
  }

  &__bar-container {
    position: relative;
    height: 50%;
    width: calc($music-player-size-sm - 4px);
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    transition: height 0.6s ease;

    @include large {
      width: calc($music-player-size-lg - 4px);
    }

    &--sound-is-playing {
      height: 100%;
    }
  }

  &__bar {
    background-color: $color-black;
    width: 4px;
    height: $music-player-size-sm;
    transform-origin: bottom;
    transition: background-color $ease-cubic;
    will-change: background-color;

    @include large {
      width: 6px;
      height: 0%;
    }

    // hide the last bar container on mobile,
    // because the bar container gets smaller
    &:last-child {
      display: none;

      @include large {
        display: block;
      }
    }
  }

  &--inverted {
    border: 1px solid $color-white !important;
    transition-delay: nth($transition-delays, 1);

    .music-player__bar {
      background-color: $color-white !important;
      transition-delay: nth($transition-delays, 1);
    }
  }

  &--nav-open {
    border: 1px solid $color-white;
    z-index: 200;

    @include large {
      border: 1px solid $color-black;
    }

    .music-player__bar {
      background-color: $color-white;

      @include large {
        background-color: $color-black;
      }
    }
  }
}
