@import './variables.module.scss';

@mixin x-small() {
	@media only screen and (min-width: map-get($grid-breakpoints, xs)) {
		@content;
	}
}

@mixin small() {
	@media only screen and (min-width: map-get($grid-breakpoints, sm)) {
		@content;
	}
}

@mixin medium() {
	@media only screen and (min-width: map-get($grid-breakpoints, md)) {
		@content;
	}
}

@mixin large() {
	@media only screen and (min-width: map-get($grid-breakpoints, lg)) {
		@content;
	}
}

@mixin x-large() {
	@media only screen and (min-width: map-get($grid-breakpoints, xl)) {
		@content;
	}
}

@mixin xx-large() {
	@media only screen and (min-width: map-get($grid-breakpoints, xxl)) {
		@content;
	}
}

@mixin xxx-large() {
	@media only screen and (min-width: map-get($grid-breakpoints, 3xl)) {
		@content;
	}
}

//
// Typography mixins
//
@mixin type-3xs {
	font-size: $font-size-3xs;
}

@mixin type-xxs {
	font-size: $font-size-xxs;
}

@mixin type-xs {
	font-size: $font-size-xs;
}

@mixin type-sm {
	font-size: $font-size-sm;
	line-height: 1.4em;
}

@mixin type-md {
	font-size: $font-size-md;
	line-height: 1.5em;
}

@mixin type-lg {
	font-size: $font-size-lg;
	line-height: 1.5em;
}

@mixin type-xl {
	font-size: $font-size-xl;
	line-height: 1.5em;
}

@mixin type-xxl {
	font-size: $font-size-xxl;
	line-height: 1.3em;
}

@mixin type-3xl {
	font-size: $font-size-3xl;
	line-height: 1.1em;
}

@mixin type-4xl {
	font-size: $font-size-4xl;
	line-height: 1.1em;
}

@mixin type-5xl {
	font-size: $font-size-5xl;
	line-height: 1.1em;
}

@mixin type-6xl {
	font-size: $font-size-6xl;
	line-height: 1.1em;
}

@mixin font-sm {
	@include type-sm;

	@include large {
		@include type-md;
	}
	@include x-large {
		@include type-lg;
	}
}
@mixin font-md {
	@include type-lg;

	@include x-large {
		@include type-xl;
	}
}
@mixin font-lg {
	@include type-lg;

	@include large {
		@include type-xl;
	}
	@include x-large {
		@include type-xxl;
	}
}
@mixin font-xl {
	@include type-xl;

	@include medium {
		@include type-xl;
	}
	@include large {
		@include type-xxl;
	}
	@include x-large {
		@include type-3xl;
	}
}
@mixin font-xxl {
	@include type-xxl;

	@include medium {
		@include type-3xl;
	}
	@include x-large {
		@include type-5xl;
	}
}

@mixin font-body {
	@include type-md;
	@include x-large {
		@include type-lg;
	}
	@include xxx-large {
		@include type-xl;
	}
}

//
// navigation
//

@mixin navigation-active-state {
	content: '';
	position: absolute;
	width: 6px;
	height: 6px;
	border-radius: 3px;
	background-color: $color-black;
	transition: .3s ease;
	// Add further scss for positioning
	@content;
}