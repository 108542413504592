@import '../variables.module.scss';
@import '../mixins.scss';

.icon {
  width: $icon-size-lg;
  height: $icon-size-lg;
  border-radius: calc($icon-size-lg / 2);
  border: 1.5px solid $color-black;
  margin-bottom: gap(2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: gap(3);

  @include medium {
    margin-top: 0;
  }

  @include xx-large {
    width: $icon-size-lg;
    height: $icon-size-lg;
    border-radius: calc($icon-size-lg / 2);
  }

  &--pool {
    path {
      &:nth-child(2) {
        animation: poolIconTop 12s linear infinite;
        transform-origin: left;
        transform-box: fill-box;
      }
      &:nth-child(3) {
        animation: poolIconBottom 12s linear infinite;
        transform-origin: left;
        transform-box: fill-box;
      }
    }

    @keyframes poolIconTop {
      0% {
        transform: scaleX(2) translate(-5px, 0);
      }
      100% {
        transform: scaleX(2) translate(-85px, 0);
      }
    }
    @keyframes poolIconBottom {
      0% {
        transform: scaleX(2) translate(-5px, 0);
      }
      100% {
        transform: scaleX(2) translate(-85px, 0);
      }
    }
  }

  &--grill {
    path {
      &:nth-child(2) {
        animation: grillIcon 6s linear infinite;
      }
      &:nth-child(4) {
        animation: grillIcon 6s linear infinite;
        animation-delay: 2s;
      }
      &:nth-child(3) {
        animation: grillIcon 6s linear infinite;
        animation-delay: 4s;
      }
    }

    @keyframes grillIcon {
      0% {
        transform: translateY(5%);
        opacity: 0;
      }
      10% {
        opacity: 1;
      }
      100% {
        transform: translateY(-10%);
        opacity: 0;
      }
    }
  }

  &--riverfront {
    path {
      &:nth-child(2) {
        transform: scaleX(1.4);
        animation: riverfrontIcon 8s linear infinite;
      }
    }

    @keyframes riverfrontIcon {
      0% {
        transform: scaleX(1.4) translate(-5px, 0);
      }
      50% {
        transform: scaleX(1.4) translate(-40px, 4px);
      }
      100% {
        transform: scaleX(1.4) translate(-80px, 0);
      }
    }
  }

  &--garden {
    path {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        opacity: 0;
      }
      &:nth-child(1) {
        animation: gardenIcon 3s infinite;
      }
      &:nth-child(2) {
        animation: gardenIcon 3s infinite;
        animation-delay: 1s;
      }
      &:nth-child(3) {
        animation: gardenIcon 3s infinite;
        animation-delay: 2s;
      }
    }

    @keyframes gardenIcon {
      0% {
        opacity: 1;
      }
      60% {
        opacity: 1;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }

  &--garage {
    path {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        transform-origin: center;
        animation: garageIcon 5s infinite;
      }
    }

    @keyframes garageIcon {
      0% {
        opacity: 0;
        transform: scale(0.5);
      }
      10% {
        opacity: 1;
      }
      60% {
        transform: scale(1);
      }
      90% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  &--pet {
    path {
      &:nth-child(5),
      &:nth-child(8) {
        animation: petIcon 5s infinite;
      }

      &:nth-child(6),
      &:nth-child(9) {
        animation: petIcon 5s infinite;
        animation-delay: 1s;
      }

      &:nth-child(7) {
        animation: petIcon 5s infinite;
        animation-delay: 2s;
      }
    }

    @keyframes petIcon {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &--bike {
    path {
      &:nth-child(1) {
        transform: translateX(0);
        transform-origin: left;
        animation: bikeIcon 3s infinite;
      }
    }

    @keyframes bikeIcon {
      0% {
        opacity: 0;
        transform: translateX(-50%);
      }
      10% {
        opacity: 1;
      }
      60% {
        transform: translateX(10%);
      }
      90% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: translateX(10%);
      }
    }
  }

  &--fitness {
    path {
      transform-origin: center;
      transform: translateY(10%);
      animation: fitnessIcon 6s infinite;
    }

    @keyframes fitnessIcon {
      0% {
        transform: scale(1);
      }
      30% {
        transform: scale(0.8) translateY(-20%);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  &--golf {
    path {
      &:last-child {
        transform-origin: center;
        transform: translateY(10%);
        animation: golfIcon 8s infinite;
      }
    }

    @keyframes golfIcon {
      0% {
        transform: translateX(40%) scale(0.6);
      }
      40% {
        transform: translateX(-10%) scale(1);
      }
      60% {
        transform: translateX(-10%) scale(1);
      }
      100% {
        transform: translateX(40%) scale(0.6);
      }
    }
  }

  &--karaoke {
    path {
      &:nth-child(5) {
        transform-origin: center;
        animation: karaokeIcon 6s infinite;
        opacity: 0;
      }
      &:nth-child(6) {
        transform-origin: center;
        animation: karaokeIcon 6s infinite;
        animation-delay: 1s;
        opacity: 0;
      }
      &:nth-child(7) {
        transform-origin: center;
        animation: karaokeIcon 6s infinite;
        animation-delay: 2s;
        opacity: 0;
      }
    }

    @keyframes karaokeIcon {
      0% {
        transform: translateY(0);
        opacity: 0;
      }
      10% {
        opacity: 1;
      }
      80% {
        transform: translateY(-50%);
        opacity: 0;
      }
    }
  }

  &--party {
    path {
      &:nth-child(1) {
        animation: partyIcon 2s infinite;
      }
      &:nth-child(2) {
        animation: partyIcon 2s infinite;
        animation-delay: 1s;
      }
    }

    @keyframes partyIcon {
      0% {
        opacity: 0;
      }
      10% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  &--co-working,
  &--lobby {
    path {
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9) {
        transform-origin: center;
        animation: lightSwitch 6s infinite;
      }
    }

    @keyframes lightSwitch {
      0% {
        opacity: 1;
      }
      60% {
        opacity: 1;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }

  &--elevator {
    path {
      &:nth-child(4) {
        animation: doorLeft 8s infinite;
      }
      &:nth-child(5) {
        animation: doorRight 8s infinite;
      }
    }

    @keyframes doorLeft {
      0% {
        transform: translateX(0);
      }
      50% {
        transform: translateX(-5%);
      }
      90% {
        transform: translateX(5%);
      }
      100% {
        transform: translateX(0);
      }
    }

    @keyframes doorRight {
      0% {
        transform: translateX(0);
      }
      60% {
        transform: translateX(5%);
      }
      90% {
        transform: translateX(-5%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  &--drag-indicator {
    border: none;
    border-radius: 0;
    animation: draganimation 3s ease infinite;
    margin: 0;
    width: $icon-size-sm;
    height: $icon-size-sm;

    @include medium {
      width: $icon-size-md;
      height: $icon-size-md;
    }

    @include xxx-large {
      width: $icon-size-lg;
      height: $icon-size-lg;
    }

    path {
      fill: $color-white;
    }

    @keyframes draganimation {
      0% {
        transform: translateX(15%);
      }
      80% {
        transform: translateX(-15%);
      }
      100% {
        transform: translateX(15%);
      }
    }
  }

  &--gallery {
    path {
      &:nth-child(1) {
        transform-origin: center;
        animation: galleryIconTopLeft 6s infinite;
      }
      &:nth-child(4) {
        transform-origin: center;
        animation: galleryIconBottomRight 6s infinite;
      }
      &:nth-child(6) {
        transform-origin: center;
        animation: galleryIconBottomLeft 6s infinite;
      }
    }

    @keyframes galleryIconTopLeft {
      0% {
        transform: translateY(-50%);
        opacity: 1;
      }
      80% {
        transform: translateY(0);
        opacity: 1;
      }
      100% {
        transform: translateY(0);
        opacity: 0;
      }
    }

    @keyframes galleryIconBottomLeft {
      0% {
        transform: translateY(50%);
        opacity: 1;
      }
      80% {
        transform: translateY(0);
        opacity: 1;
      }
      100% {
        transform: translateY(0);
        opacity: 0;
      }
    }

    @keyframes galleryIconBottomRight {
      0% {
        transform: translateX(50%);
        opacity: 1;
      }
      80% {
        transform: translateX(0);
        opacity: 1;
      }
      100% {
        transform: translateX(0);
        opacity: 0;
      }
    }
  }

  &--piggy-bank {
    path {
      &:nth-child(2) {
        transform-origin: center;
        animation: piggyBankIcon 3s infinite;
      }
      &:nth-child(3) {
        transform-origin: center;
        animation: piggyBankIcon 3s infinite;
      }
    }

    @keyframes piggyBankIcon {
      0% {
        transform: translateY(-40%);
        opacity: 1;
      }
      80% {
        opacity: 1;
      }
      90% {
        opacity: 0;
      }
      100% {
        transform: translateY(12%);
        opacity: 0;
      }
    }
  }
}
