@import '../variables.module.scss';
@import '../mixins.scss';

.article {
	margin-bottom: gap(4);
	white-space: break-word;

	&__image-container {
		border-radius: 0 0 gap(2) 0;
		overflow: hidden;
		position: relative;
		width: 100%;
		height: auto;
		filter: grayscale(1);
		will-change: filter;
		transition: filter $ease-link;
	}

	&__image {
		transition:
      filter $ease-link,
      transform $ease-link;
		will-change:
      transform,
      filter;
	}

	&__body {
		padding-top: gap(1);

		@include medium {
			padding: gap(2);
		}
	}

	&__title {
		@include type-xl;
		letter-spacing: -0.06em !important;
	}

	&__text {
		@include type-sm;
		margin: gap(1) 0 gap(3);

		@include medium {
			@include type-sm;
		}
	}

	&__arrow {
		background: url(/arrow.svg) no-repeat center;
		transform: rotate(-90deg);
		background-size: contain;
		height: 36px;
		width: 40px;
		transition: transform 0.4s ease-out;
		will-change: transform;
	}

	&:hover {
		.article__image-container {
			filter: grayscale(0) !important;
		}

		.article__image {
			transform: scale(1.05);
		}

		.article__arrow {
			transform: rotate(-90deg) translate3d(0, 10px, 0);
		}
	}
}
