@import "../variables.module.scss";

.notification {
  position: fixed;
  bottom: gap(2);
  left: gap(8);
  right: gap(2);
  padding: gap(2);
  background-color: $color-white;
  transform: translateY(calc(100% + gap(2)));
  transition: $ease-page-transition;
  border-radius: 10px;
  border: 1px solid $color-black;
  opacity: 0;
  z-index: $z-index-notification;

  @include medium {
    bottom: gap(3);
    right: gap(3);
    left: auto;
    transform: translateY(calc(100% + gap(3)));
  }

  &--show {
    transform: translateY(0);
    opacity: 1;
  }
}