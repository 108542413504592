@import '../variables.module.scss';
@import '../mixins.scss';

.logo {
  max-width: 100%;
  max-height: 100%;
}

.logo-container {
  position: relative;
  height: 55px;
  width: 90px;

  @include large {
    width: 100px;
    height: 61px;
  }

  & > svg > circle {
    // transition: all 0.5s ease;
    transform-origin: center;
    will-change: transform;
  }

  &--loading {
    & > svg > circle {
      animation: rotating 1s linear infinite;
    }
  }

  &--show {
    opacity: 1;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-outer-wrapper {
  position: relative;

  &__circle {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 36px;
    top: -1px;

    @include large {
      width: 22px;
      height: 22px;
      left: 40px;
      top: -1px;
    }
  }

  // &--colored {
  //   height: 35px;

  //   @include large {
  //     width: 130px;
  //     height: 52px;
  //   }
  // }
}
