.text-container {
  &--read-more {
    &--active {
      max-height: 60vh;
      overflow: hidden;
      position: relative;

      @include x-large {
        max-height: 40vh;
      }

      &::after {
        content: '';
        background: $color-light-peach;
        background: -moz-linear-gradient(
          180deg,
          rgba($color-light-peach, 0) 0%,
          rgba($color-light-peach, 1) 100%
        );
        background: -webkit-linear-gradient(
          180deg,
          rgba($color-light-peach, 0) 0%,
          rgba($color-light-peach, 1) 100%
        );
        background: linear-gradient(
          180deg,
          rgba($color-light-peach, 0) 0%,
          rgba($color-light-peach, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc6a9",endColorstr="#ffc6a9",GradientType=1);
        height: 10vh;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}
