:root {
  --size: 150;
  --unit: calc((var(--size) / 1366) * 1.2vmin);
  --background: $color-gray-lighter;
}

.collage-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10vh;

  @include medium {
    align-items: center;
    padding-bottom: unset
  }

  &--over {
    position: absolute;
    z-index: 50;
  }
}

.collage-container {
  position: relative;
  min-width: calc(1366 * var(--unit));
  height: calc(768 * var(--unit));

  &--static {
    position: relative;
    height: 100%;
    width: calc(100% + 60px);
    margin: 0 calc(-30px);
  }

  &--frontpage {
    margin-bottom: 10vh;

    @include small {
      margin-bottom: unset;
    }
  }
}

.collage {
  width: 100%;

  &__layer {
    pointer-events: none;
  }

  #butterfly {
    position: absolute;
    top: -20%;
    left: 47.8%;
    width: calc(120 * var(--unit));
    height: auto;

    @include medium {
      top: 10%;
      left: 32%;
    }

    @include large {
      top: 20%;
      left: 76%;
    }
  }

  #plant-center-back {
    position: absolute;
    top: 4%;
    left: 41.4%;
    width: calc(280 * var(--unit));
    height: auto;
  }

  #plant-left-back {
    position: absolute;
    top: 40%;
    left: 20.7%;
    width: calc(360 * var(--unit));
    height: auto;
  }

  #plant-right-back {
    position: absolute;
    top: 34%;
    left: 54%;
    width: calc(200 * var(--unit));
    height: auto;
  }

  #flowers-center-front {
    position: absolute;
    top: 46%;
    left: 42%;
    width: calc(180 * var(--unit));
    height: auto;
  }

  #flowers-white-front {
    position: absolute;
    top: 56%;
    left: 50%;
    width: calc(220 * var(--unit));
    height: auto;
  }

  #oranges {
    position: absolute;
    top: 60%;
    left: 50%;
    width: calc(160 * var(--unit));
    height: auto;
  }

  #girl-bikini {
    position: absolute;
    top: 60%;
    left: 42%;
    width: calc(50 * var(--unit));
    height: auto;

    @include large {
      width: calc(40 * var(--unit));
    }
  }

  #fish-left {
    position: absolute;
    top: 72%;
    left: 40%;
    width: calc(80 * var(--unit));
    height: auto;

    @include large {
      left: 40%;
    }
  }

  #flower-left-back {
    position: absolute;
    top: 24%;
    left: 30%;
    width: calc(240 * var(--unit));
    height: auto;
  }

  #seaowl {
    position: absolute;
    top: 18%;
    left: 64%;
    width: calc(80 * var(--unit));
    height: auto;

    @include large {
      left: 20%;
      top: 20%
    }
  }

  #surfer-right {
    position: absolute;
    top: 70%;
    left: 46%;
    width: calc(200 * var(--unit));
    height: auto;
    z-index: 10;
  }

  #searoses-right-front {
    position: absolute;
    top: 68%;
    left: 35%;
    width: calc(200 * var(--unit));
    height: auto;
  }

  #parrot {
    position: absolute;
    top: 30%;
    left: 55%;
    width: calc(100 * var(--unit));
    height: auto;
  }

  #boat {
    position: absolute;
    top: 60%;
    left: 30%;
    width: calc(100 * var(--unit));
    height: auto;
  }

  #surfer {
    position: absolute;
    top: 74%;
    left: 40%;
    width: calc(80 * var(--unit));
    height: auto;
    z-index: 10;
  }
}