@import '../../styles/variables.module.scss';
@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';

$list-inner-space-sm: 12px;
$list-inner-space-lg: 30px;

.list {
	margin-bottom: gap(5);

	&__headline {
		@include font-lg;
		font-family: $font-family-bold;
	}

	&__btn {
		max-height: 38px;
		align-items: center;
		border: 1px solid $color-black;
		padding: 0 gap(1);

		@include large {
			padding: 0 gap(2);
		}

		&--icon {
			cursor: pointer;
			display: inline-flex;

			&:not(:last-child) {
				margin-right: calc(#{$bsGutter} * 0.5);

				@include medium {
					margin-right: $bsGutter;
				}

				@include large {
					margin-right: gap(1)
				}
			}
		}
	}

	&__header {
		position: relative;
		background-color: $color-white;
		padding: gap(3) 0;
		display: none;

		@include large {
			display: block;
		}

		&:before,
		&:after {
			content: '';
			display: block;
			background: $color-white;
			position: absolute;
			height: 100%;
			max-height: 100%;
			width: 30px;
			top: 0;
		}

		&:before {
			left: -30px;
		}

		&:after {
			right: -30px;
		}

		p {
			font-weight: 900;
			margin: 0;
		}
	}

	&__divider {
		margin: 0 5px;
	}

	&__item-container {
		margin: 0;
		padding: 0;
	}

	&__item {
		padding: $list-inner-space-sm 0;
		border-top: 1px dashed $color-black;
		list-style: none;

		&:first-child {
			margin-top: $list-inner-space-sm;
			border-top: none;
		}

		&--availability {
			list-style: none;
			border-top: 1px solid $color-white;

			&::marker {
				content: none;
			}
		}

		&__title {
			font-size: $font-size-xl !important;
			margin-bottom: gap(2);

			@include large {
				font-size: $font-size-xs !important;
				margin-bottom: 0;
			}

			&__addition {
				font-size: $font-size-xl !important;

				@include large {
					display: none;
				}
			}
		}
	}

	&__error {
		padding: gap(3) 0;
	}

	&__input {
		display: none;
	}

	&__spacing {
		margin: 0 0 gap(2) 0;

		@include large {
			display: none;
		}
	}
}

.list-section {
	&--availability {
		p,
		span,
		a {
			@include type-sm;
		}
	}
}