@import '../../styles/variables.module.scss';

$cookie-btn-size: 50px;
$border-radius: calc(#{$cookie-btn-size} / 2);

.CookieConsent {
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: 0;
  padding: 25px;
  left: 50%;
  transform: translateX(-50%);
  background: $color-black;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  // border: 1px solid $color-brand-primary;
  border-bottom: none;
  border-radius: $border-radius $border-radius 0 0;
  z-index: $z-index-cookie;

  & * {
    font-size: $font-size-xs !important;
  }

  &__text {
    margin: 0;
  }

  button {
    height: $cookie-btn-size;
    width: $cookie-btn-size;
    border: 1px solid $color-white;
    border-radius: calc(#{$cookie-btn-size} / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 1em;
    background: transparent;
    font-family: $font-family-bold;
    text-transform: uppercase;
    transition: all 0.3s ease;

    &:hover {
      cursor: pointer;
      background: $color-white;
      color: $color-black;
    }
  }

  * {
    color: $color-white;
    font-size: $font-size-xxs;
  }
}
