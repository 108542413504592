@import '../variables.module.scss';

.wrapper {
	transition: all 1s ease;
}

.main {
  min-height: 100vh;
  // overflow-x: hidden;
  position: relative;
  z-index: $z-index-main-content;
  padding-top: gap(10);
}
