@import './variables.module.scss';

.color {
  &--background {
    &--black {
      background-color: $color-black;
    }
    &--white {
      background-color: $color-white;
    }
    &--pink {
      background-color: $color-pink;
    }
    &--yellow {
      background-color: $color-yellow;
    }
    &--blue {
      background-color: $color-blue-primary;
    }
    &--blue-light {
      background-color: $color-blue-light;
    }
    &--green {
      background-color: $color-green;
    }
    &--green-light {
      background-color: $color-green-light;
    }
    &--orange {
      background-color: $color-orange-light;
    }
    &--beige {
      background-color: $color-beige;
    }
    &--brown-light {
      background-color: $color-brown-light;
    }
  }
  &--black {
    color: $color-black;
  }
  &--white {
    color: $color-white;
  }
  &--pink {
    color: $color-pink;
  }
  &--yellow {
    color: $color-yellow;
  }
  &--blue {
    color: $color-blue-primary;
  }
  &--blue-light {
    color: $color-blue-light;
  }
  &--green-dark {
    color: $color-green-dark;
  }
  &--green-darker {
    color: $color-green-darker;
  }
  &--green-light {
    color: $color-green-light;
  }
  &--orange {
    color: $color-orange-light;
  }
  &--beige {
    color: $color-beige;
  }
  &--brown-light {
    color: $color-brown-light;
  }
}
