@import '../variables.module.scss';
@import '../mixins.scss';

.video-container,
.image-container {
  position: relative;
}

.image-container {
  display: flex;
  justify-content: center;
  margin: 0 calc(#{- gap(1)});

  @include medium {
    margin: unset;
  }

  &--border-radius {
    border-radius: $border-radius-sm;
    overflow: hidden;
  }

  &--vertical {
    padding-top: calc((16 / 9) * 100%);
  }

  &--horizontal {
    padding-top: calc((9 / 16) * 100%);
  }

  &--square {
    padding-top: 100%;
  }

  &--offset {
    &-full {
      margin: 0 calc(#{- gap(1)});

      @include medium {
        margin: 0 calc(#{- gap(3)});
      }
    }
    &-right {
      margin: 0 calc(#{- gap(1)});

      @include medium {
        margin: 0 calc(#{- gap(2)}) 0 0;
      }

      &--full {
        margin: 0 calc(#{- gap(1)});

        @include medium {
          margin: 0 calc(#{- gap(3)}) 0 0;
        }
      }
    }

    &-left {
      margin: 0 calc(#{- gap(1)});

      @include medium {
        margin: 0 0 0 calc(#{- gap(2)});
      }

      &--full {
        margin: 0 calc(#{- gap(1)});

        @include medium {
          margin: 0 0 0 calc(#{- gap(3)});
        }
      }
    }

    &-mobile {
      margin: 0 calc(#{- gap(3)});
    }
  }

  &--content-left {
    justify-content: flex-start;
  }

  &--content-right {
    justify-content: flex-end;
  }

  img {
    object-fit: cover;
  }

  &__hero-overlaying-logo {
    z-index: 30;
    position: absolute;
    top: 10%;
    object-fit: none;
    z-index: 30;
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    span {
      width: 180px !important;

      @include medium {
        width: 300px !important;
      }
      @include x-large {
        width: 400px !important;
      }
      @include xx-large {
        width: 500px !important;
      }
    }
  }

  &--hero {
    position: relative;
    height: calc(100vh - #{$header-height});
    min-height: -webkit-fill-available;
    margin: 0 $offset-md 0 $offset-md;
    border-radius: 0 0 0 $border-radius-lg;
    overflow: hidden;

    img {
      object-fit: cover;
    }

    &--frontpage {
      position: sticky;
      top: 100px;
    }
  }

  &--contain {
    img {
      object-fit: contain;
    }
  }
}

.container {
  &--border-radius {
    border-radius: $border-radius-sm;
    overflow: hidden;
  }
  &--offset-right {
    margin: 0 calc(#{- gap(1)});

    @include medium {
      margin: 0 calc(#{- gap(2)}) 0 0;
    }

    &--full {
      margin: 0 calc(#{- gap(1)});

      @include medium {
        margin: 0 calc(#{- gap(3)}) 0 0;
      }
    }
  }

  &--offset-left {
    margin: 0 calc(#{- gap(1)});

    @include medium {
      margin: 0 0 0 calc(#{- gap(2)});
    }

    &--full {
      margin: 0 calc(#{- gap(1)});

      @include medium {
        margin: 0 0 0 calc(#{- gap(3)});
      }
    }
  }

  &--offset-full {
    margin: 0 calc(#{- gap(1)});

    img,
    span {
      width: 100%;
      max-width: unset;
    }

    @include medium {
      margin: 0 calc(#{- gap(3)});
    }
  }
}
