@import './variables.module.scss';

.slider-navigation {
  position: absolute;
  right: gap(4);
  top: gap(4);
  z-index: $z-index-main-content;
}

.swiper-button-prev,
.swiper-button-next {
  width: $control-button-size;
  height: $control-button-size;
  z-index: $z-index-main-content;
  position: absolute;
  top: gap(3);
  right: gap(3);
  display: none;
  align-items: center;
  justify-content: center;

  @include large {
    display: flex;
  }

  &:after {
    content:url('/arrow.svg');
    transform: rotate(90deg);
    margin-left: -5px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    background: $color-white;
    height: 100%;
    width: 100%;
    border-radius: calc(#{$control-button-size} / 2);
    transition: transform 0.3s ease-in-out;
    box-shadow: $shadow;
    z-index: -1;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1); // overwriting swiper styles

    &:before {
      transform: scale(1.1);
    }
  }
}

.swiper-button-next {
  top: calc(gap(3) + $control-button-size + gap(1));

  &:after {
    transform: rotate(-90deg);
    margin-right: -8px;
  }
}

.swiper-button-disabled {
  opacity: .9;
  cursor: not-allowed !important;
  transform: none;

  &:after {
    opacity: .5;
  }

  &:hover {
    &:before {
      transform: none;
    }
  }
}
