@import '../../styles/variables.module.scss';
@import '../../styles/mixins.scss';

.pin-spacer {
  height: 100vh;
  margin-top: -100px;
}

.intro-header {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 gap(2);
  text-align: center;
}

.hero {
  position: relative;
  height: 100vh;

  &__curtain {
    width: calc(50vw + 10px);
    background-color: $color-light-peach;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: transform 0.2s ease;
    will-change: transform;

    &::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 100%;
      background-color: $color-light-peach;
      right: -gap(1);
      top: 0;
      bottom: 0;
      transition: transform 0.6s ease;
      will-change: transform;
    }
  }

  &--frontpage {
    z-index: -1;

    &--fixed {
      position: fixed;
    }

    &__background {
      top: 0;
      right: auto;
      left: auto;
      bottom: 0;
      position: fixed;
      width: 100vw;
      height: calc(100vh + 100px);
      background: url('/hero-back-new.jpg') no-repeat center;
      background-size: cover;
      z-index: -1;

      @include large {
        top: 100px;
        height: calc(100vh - 100px);
      }
    }
  }

  &__inverted-border-radius {
    position: absolute;
    left: -50px;
    bottom: 0;
    height: 50px;
    width: 50px;
    background-color: transparent;
    border-radius: 25px 25px 25px 0;
    z-index: 10;
    transform: rotate(-90deg);

    &::before {
      content: '';
      position: absolute;
      background-color: transparent;
      bottom: -50px;
      height: 50px;
      width: 25px;
      border-top-left-radius: 25px;
      box-shadow: 0 -25px 0 0 $color-light-peach;
    }
  }
}
