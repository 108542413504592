@import '../variables.module.scss';

.preloader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $z-index-preloader;
  background: $color-white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > .logo-outer-wrapper {
    position: relative;
    z-index: 1;

    .logo-container {
      transform: scale(2);
    }

    // &:before {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   z-index: -1;
    //   top: -50px;
    //   left: -120px;
    //   height: 100px;
    //   width: 100px;
    //   transform: rotate(-30deg);
    //   background: url('/map-plant.png') center no-repeat;
    //   background-size: contain;

    //   animation-name: plantLeft;                // Name der Animation
    //   animation-duration: 2s;               // Dauer der Animation
    //   animation-iteration-count: 1;  // Anzahl der Wiederholungen
    // }

    // &:after {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   z-index: -1;
    //   top: -30px;
    //   right: -120px;
    //   height: 100px;
    //   width: 100px;
    //   transform: rotate(30deg);
    //   background: url('/map-plant.png') center no-repeat;
    //   background-size: contain;

    //   animation-name: plantRight;                // Name der Animation
    //   animation-duration: 2s;               // Dauer der Animation
    //   animation-iteration-count: 1;  // Anzahl der Wiederholungen
    // }
  }
}

@keyframes plantLeft {
  from {
    transform: rotate(50deg) scale(0);
    opacity: 0;
  }
  to {
    transform: rotate(-30deg) scale(1);
    opacity: 1;
  }
}

@keyframes plantRight {
  from {
    transform: rotate(-50deg) scale(0);
    opacity: 0;
  }
  to {
    transform: rotate(30deg) scale(1);
    opacity: 1;
  }
}
