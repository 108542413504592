@import '../../styles/variables.module.scss';
@import '../../styles/mixins.scss';

.section {
	position: relative;
	padding-top: 0;
	padding-bottom: calc($section-gap-md / 2);
	z-index: $z-index-main-content;

	@include medium {
		padding-bottom: calc($section-gap-lg / 2);
	}

	&--t {
		padding-top: calc($section-gap-md / 2);

		@include medium {
			padding-top: calc($section-gap-lg / 2);
		}

		&--sm {
			padding-top: calc($section-gap-md / 2);

			@include medium {
				padding-top: calc($section-gap-lg / 4);
			}
		}

		&--lg {
			padding-top: calc($section-gap-md / 2);

			@include medium {
				padding-top: $section-gap-lg;
			}
		}
	}

	&--b {
		&--sm {
			padding-bottom: calc($section-gap-md / 2);

			@include medium {
				padding-bottom: calc($section-gap-lg / 4);
			}
		}

		&--lg {
			padding-bottom: calc($section-gap-md / 2);

			@include medium {
				padding-bottom: $section-gap-lg;
			}
		}
	}

	&--nb {
		padding-bottom: 0;

		&--mobile {
			padding-bottom: 0;

			@include medium {
				padding-bottom: calc($section-gap-md / 2);
			}
		}
	}

	&--np {
		padding: 0;
	}

	&--full-height {
		min-height: 100vh;

		img {
			object-fit: cover;
		}
	}

	&--footer {
		z-index: $z-index-footer;
	}
}
