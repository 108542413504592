@import '../variables.module.scss';

.accessibility-button,
.acsb-trigger {
  position: fixed;
  z-index: $z-index-accessibility-button;
  width: $accessibility-btn-size-sm;
  height: $accessibility-btn-size-sm;
  border-radius: 50%;
  bottom: gap(2) !important;
  left: gap(2) !important;
  background-color: $color-black;
  cursor: pointer;
  transition: border-color $ease-cubic;

  @include medium {
    left: gap(3) !important;
    bottom: gap(3) !important;
  }

  @include large {
    width: $accessibility-btn-size-lg;
    height: $accessibility-btn-size-lg;
  }

  &--inverted {
    border: 1px solid $color-white !important;
    transition-delay: nth($transition-delays, 1);
  }

  &--nav-open {
    border: 1px solid $color-white;
    z-index: 200;

    @include large {
      border: 1px solid $color-black;
    }
  }
}
