@import '../../styles/variables.module.scss';
@import '../../styles/mixins.scss';

.iframe {
	height: 100%;
	min-height: 70vh;
	min-width: 100vw;
	border: none;
	overflow: hidden;
	pointer-events: all;

	&--active {
		pointer-events: all;
	}
}

.iframe-holder {
	position: relative;

	&__scroll-overlay {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 6px;
		pointer-events: all;
		background-color: rgba($color-black, .6);

		&--active {
			display: flex;
		}
	}

	&__button-start,
	&__button-stop {
		right: gap(3);
		left: gap(10);
		bottom: gap(3);

		&>* {
			width: 100%;
			justify-content: center;
		}
	}

	&__button-start {
		display: none;
		position: absolute;

		@include small {
			left: 50%;
			top: 50%;
			right: unset;
			bottom: unset;
			transform: translate(-50%, -50%);
		}
	}

	&__button-stop {
		display: none;
		position: absolute;
		bottom: gap(3);

		@include small {
			right: gap(3);
			bottom: gap(5);
			left: unset;
			top: unset;
		}
	}

	&__button-start,
	&__button-stop {
		&--active {
			display: flex;
		}
	}
}
