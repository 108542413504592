@import '../variables.module.scss';
@import '../mixins.scss';
@import '../typography.scss';

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  padding: 0 gap(2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-white;
  transition: background-color $ease-page-transition,
    border-color $ease-page-transition;
  will-change: background-color, border-color;
  z-index: 200;

  @include small {
    padding: 0 #{$burger-size + gap(7)} 0 gap(3);
  }

  @include large {
    position: fixed;
    padding: 0 #{$burger-size + gap(5)} 0 gap(3);
  }

  @include x-large {
    padding: 0 #{$burger-size + gap(7)} 0 gap(3);
  }

  &--dark {
    border-color: $color-black;

    .nav--sub {
      border-color: $color-black;
    }
  }

  &__burger {
    position: fixed;
    height: $burger-size;
    width: $burger-size;
    border-radius: calc(#{$burger-size} / 2);
    background-color: $color-black;
    border: 1px solid $color-black;
    padding: 0 10px;
    z-index: 100;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
    transition: all $ease-cubic;
    top: gap(2);
    right: gap(2);

    @include small {
      margin-left: gap(3);
    }

    @include large {
      position: absolute;
      border: none;
      background-color: transparent !important;
      top: calc((#{$header-height} - #{$burger-size}) / 2);
      right: gap(3);
      padding: 0;
    }

    &:hover {
      &:before {
        @include large {
          width: 100%;
        }
      }
    }

    &:before,
    &:after {
      content: '';
      background-color: $color-white;
      position: absolute;
      width: 30px;
      height: 2px;
      border-radius: 4px;
      transform: translateY(-4px);
      transition: all 0.3s ease-out;

      @include large {
        width: 100%;
        background-color: $color-black;
      }
    }

    &:before {
      width: 20px;
      transform: translateY(4px);

      @include large {
        width: 70%;
      }
    }

    &--active {
      align-items: center;
      background-color: transparent;
      transform: rotate(180deg);
      border: 1px solid $color-white;

      @include large {
        border: none;
        top: 120%;
        background-color: inherit !important;
      }

      &:before,
      &:after {
        width: 20px;

        @include large {
          background-color: $color-black;
          width: 100%;
        }
      }
      &:after {
        transform: rotate(45deg) translate(0);
      }
      &:before {
        transform: rotate(-45deg) translate(0);
      }
    }
  }

  &__nav-container {
    display: flex;
    align-items: center;
    z-index: 9;
  }

  // Styling for pages with inverted colors due to a black background. (Example: legal pages)
  &--inverted {
    a {
      color: $color-white;
    }

    .header__burger {
      &:after,
      &:before {
        background-color: $color-white;
      }
    }

    .button {
      border: 1px solid $color-black;
      color: $color-white;

      @include large {
        color: $color-black;
      }

      &:hover {
        background-color: $color-black !important;
        border: 1px solid $color-white;
        color: $color-white;
      }
    }

    .nav {
      &--main,
      &--sub {
        &__item {
          a {
            &:after,
            &:before {
              background-color: $color-white;
            }
          }
        }
      }

      .social-media {
        svg {
          path {
            fill: $color-white;
          }
        }
      }
    }
  }
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  min-height: -webkit-fill-available;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  background-color: $color-black !important;
  padding: 0 gap(2);
  transition: transform $ease-cubic, background-color $ease-page-transition,
    border-color $ease-page-transition;
  will-change: transform, background-color, border-color;

  @include medium {
    padding: 0 gap(3);
  }

  @include large {
    position: absolute;
    background-color: inherit !important;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: none;
    width: auto;
    height: auto;
    min-height: auto;
    position: inherit;
    padding: 0;
    will-change: background-color, border-color;
  }

  * {
    hyphens: none;
  }

  .logo-outer-wrapper {
    transform: translate3d(10px, 0, 0);
    opacity: 0;
    display: flex;
    position: absolute;
    left: gap(2);
    top: gap(2.5);
    transition: transform $ease-nav-item, opacity $ease-nav-item;
    will-change: transform, opacity;

    @include large {
      display: none;
    }
  }

  &--active {
    transform: translateX(0%);

    @include large {
      transform: none;
    }

    .logo-outer-wrapper {
      transition-delay: nth($transition-delays, 1);
      opacity: 1;
      transform: none;
    }

    .nav--main,
    .nav--sub {
      li {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        will-change: transform, opacity;
      }
    }

    .social-media {
      opacity: 1;
    }

    .nav--main {
      &__item {
        &:nth-child(4) {
          transition-delay: nth($transition-delays, 1);
        }
        &:nth-child(5) {
          transition-delay: nth($transition-delays, 2);
        }
        &:nth-child(6) {
          transition-delay: nth($transition-delays, 2);
        }
        &:nth-child(1) {
          transition-delay: nth($transition-delays, 3);
        }
        &:nth-child(2) {
          transition-delay: nth($transition-delays, 4);
        }
        &:nth-child(3) {
          transition-delay: nth($transition-delays, 5);
        }
      }
    }

    .nav--sub {
      &__item {
        &:nth-child(1) {
          transition-delay: nth($transition-delays, 6);
        }
        &:nth-child(2) {
          transition-delay: nth($transition-delays, 7);
        }
        &:nth-child(3) {
          transition-delay: nth($transition-delays, 8);
        }
        &:nth-child(4) {
          transition-delay: nth($transition-delays, 9);
        }
        &:nth-child(5) {
          transition-delay: nth($transition-delays, 10);
        }
        &:nth-child(6) {
          transition-delay: nth($transition-delays, 11);
        }
      }
    }
  }

  &-container {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    top: gap(10);
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    padding: gap(5);
    border-left: 1px solid $color-white;

    @include small {
      left: auto;
    }

    @include large {
      padding: gap(5) gap(15) gap(5) gap(5);
    }
  }

  &--main,
  &--sub {
    ul,
    li {
      width: 100%;
    }

    li,
    a {
      text-align: center;
      font-family: $font-family-bold;

      @include large {
        text-align: left;
      }
    }

    li {
      transform: translate3d(10px, 0, 0);
      opacity: 0;
      will-change: transform, opacity;
      transition: transform $ease-nav-item, opacity $ease-nav-item;

      @include large {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }

    &__item {
      color: $color-white;
      margin: 0 0 gap(1.5) 0;

      &:nth-child(4) {
        order: 3;
      }
      &:nth-child(5) {
        order: 1;
      }
      &:nth-child(6) {
        order: 2;
      }
      &:nth-child(1) {
        order: 4;
      }
      &:nth-child(2) {
        order: 5;
      }
      &:nth-child(3) {
        order: 6;
      }

      @include large {
        color: $color-black;
        margin: 0;
      }

      &--includes-button {
        @include large {
          color: $color-black;
        }

        .button {
          background-color: $color-black;
          border: 1px solid $color-white;

          @include large {
            font-size: $font-size-sm !important;
            background-color: $color-white;
            border: none;

            &:hover {
              background-color: $color-black;
            }
          }

          &--filled-transparent {
            background-color: transparent;
            border-color: $color-white !important;
            color: $color-white;

            @include large {
              color: $color-black;
              border-color: $color-black !important;
            }
          }
        }

        &--current {
          .button {
            background-color: $color-black;
            border: 1px solid $color-white;

            @include large {
              font-size: $font-size-sm !important;
              background-color: $color-black;
              color: $color-white;
              border: none;
            }
          }
        }
      }
    }
  }

  &--main {
    display: flex;

    ul {
      display: flex;
      align-items: center;
      flex-direction: column;
      align-items: flex-start;

      @include large {
        flex-direction: row;
        align-items: center;
      }
    }

    a {
      font-family: $font-family-bold;
      font-size: $font-size-lg;

      @include large {
        font-size: $font-size-sm;
      }
    }

    &__item {
      @include large {
        margin-right: gap(2);

        &:nth-child(1) {
          order: 1;
        }
        &:nth-child(2) {
          order: 2;
        }
        &:nth-child(3) {
          order: 3;
        }
        &:nth-child(4) {
          order: 4;
        }
        &:nth-child(5) {
          order: 5;
        }
        &:nth-child(6) {
          order: 6;
        }

        a {
          position: relative;

          &:after {
            @include navigation-active-state {
              bottom: -10px;
              left: 50%;
              opacity: 0;
              transform: translateX(-50%) translateY(0) scale(0);
            }
          }
          &:hover {
            &:after {
              transform: translateX(-50%) translateY(4px) scale(1);
              opacity: 1;
            }
          }
        }

        &--current {
          a {
            &:after {
              transform: translateX(-50%) translateY(4px) scale(1);
              opacity: 1;
            }
          }
        }
      }

      @include x-large {
        margin-right: gap(4);
      }

      &--includes-button {
        a {
          font-size: $font-size-md !important;

          &:after {
            content: none;
          }
        }

        @include large {
          margin-right: gap(1);

          &:last-child {
            margin-right: 0;
          }
        }

        @include x-large {
          margin-right: gap(2);

          &:last-child {
            margin-right: 0;
          }
        }

        .button {
          min-height: $button-height-sm;
        }
      }
    }

    &-mobile {
      display: flex;
      margin-bottom: gap(6);

      @include large {
        display: none;
      }

      li,
      a {
        @include large {
          font-size: $font-size-xl;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &--sub {
    display: flex;
    flex-direction: column;
    background-color: $color-black !important;
    transition: background-color $ease-page-transition,
      border-color $ease-page-transition;

    @include large {
      background-color: inherit !important;
      justify-content: center;
      position: absolute;
      top: 100px;
      height: calc(100vh - $header-height);
      right: 0;
      transform: translateX(100%);
      padding: gap(3) gap(15) gap(3) gap(5);
      border-left: 1px solid $color-white;
      transition: transform $ease-cubic, background-color $ease-page-transition,
        border-color $ease-page-transition;
      will-change: transform, background-color, border-color;
    }

    a,
    li {
      font-size: $font-size-lg;

      @include large {
        font-size: $font-size-xxl;
      }
    }

    &__item {
      &:last-child {
        margin: 0;
      }

      a {
        @include large {
          transition: padding-left 0.3s !important;
        }

        &:after {
          @include large {
            @include navigation-active-state {
              top: 50%;
              left: 0;
              opacity: 0;
              transform: translate(-200%, -50%) scale(0);
            }
          }
        }

        &:hover {
          &:after {
            @include large {
              transform: translate(-300%, -50%) scale(1);
              opacity: 1;
            }
          }
        }
      }

      &--current {
        a {
          &:after {
            @include large {
              transform: translate(-300%, -50%) scale(1);
              opacity: 1;
            }
          }
        }
      }
    }

    &--active {
      @include large {
        transform: translateX(0);
      }
    }
  }

  &__social-media {
    position: absolute;
    bottom: gap(3);
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: gap(1);

        @include large {
          margin-right: gap(1.5);
        }
      }
    }
  }
}

.social-media {
  width: $socials-btn-size-sm;
  height: $socials-btn-size-sm;
  position: absolute;
  align-self: flex-end;
  cursor: pointer;
  transition: opacity $ease-nav-item;
  bottom: gap(2);
  right: gap(2);
  opacity: 0;
  will-change: opacity;

  @include medium {
    width: $socials-btn-size-lg;
    height: $socials-btn-size-lg;
  }

  @include large {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    position: absolute;
    left: gap(5);
    bottom: gap(3);
  }

  svg {
    fill: $color-white;
    width: $socials-btn-size-sm;
    height: $socials-btn-size-sm;
    border-radius: calc(#{$socials-btn-size-sm} / 2);
    transition: all $ease-nav-item;

    &:hover {
      background-color: $color-black;

      path {
        fill: $color-white !important;
      }
    }

    @include large {
      fill: $color-black;
    }

    path {
      transition: all $ease-nav-item;
    }
  }

  a {
    display: block;
    font-size: 0;
  }

  &__button {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid $color-white;
    background-color: $color-black;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    transition-delay: none;

    svg {
      width: 14px;
      height: 14px;
    }

    @include large {
      display: none;
    }
  }

  &__icons {
    position: absolute;
    top: 0;
    bottom: 0;

    @include large {
      position: relative;
      display: flex;
    }

    li {
      position: absolute;
      top: 0;
      bottom: 0;
      width: fit-content;

      @include large {
        position: relative;

        &:not(:last-child) {
          margin-right: gap(1);
        }
      }
    }

    &--show {
      li {
        &:nth-child(1) {
          transform: translateY(-50px);
        }
        &:nth-child(2) {
          transform: translateY(-100px);
        }
        &:nth-child(3) {
          transform: translateY(-150px);
        }
        &:nth-child(4) {
          transform: translateY(-200px);
        }

        @include large {
          transform: translateY(0) !important;
        }
      }
    }
  }
}
