@import '../../styles/variables.module.scss';

.mapbox-section {
  border-top: 1px solid $color-black;
  border-bottom: 1px solid $color-black;
}

.mapbox-row {
  @include large {
    display: flex;
    justify-content: center;
  }
}

.mapbox {
  &__filter-outer-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: gap(3) 0;

    @include large {
      min-width: fit-content;
      margin: gap(5) gap(3) gap(5) 0;
      height: auto;
    }

    @include x-large {
      min-width: 500px;
    }
  }

  &__map {
    // TODO: use variables
    min-height: 400px;
    width: calc(100% + 40px);
    height: calc(80vh - #{$form-height} - #{gap(4)});
    margin-left: -20px;
    position: relative;

    @include medium {
      width: calc(100% + 60px);
      margin-left: -30px;
    }

    @include large {
      width: calc(100% + 30px);
      height: calc(100vh - #{$header-height});
      margin-left: 0;
      margin-right: calc(#{- gap(3)});
    }
  }

  &__dropdown {
    width: 100%;
    max-width: $max-width-filter;

    @include large {
      display: none;
    }

    & > .button {
      width: 100%;
    }
  }

  &__filter-container {
    position: absolute;
    z-index: $z-index-mapbox-filter-container;
    top: calc(#{$button-height-sm} + #{gap(1)});
    padding: gap(2);
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    border-radius: $border-radius-md;
    width: 100%;
    max-width: $max-width-filter;
    display: none;
    overflow: hidden;

    @include large {
      border-radius: 0;
      padding: 0;
      position: relative;
      top: auto;
      background: none;
      backdrop-filter: none;
      width: auto;
      display: block;
      max-width: unset;
      width: 100%;
    }

    &--active {
      display: block;
    }
  }

  // &__scroll-container {
  // 	overflow: scroll;
  // }

  &__filter-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__results-container {
    display: none;
    padding: gap(2) 0 gap(3) gap(3);

    @include x-large {
      display: block;
      position: absolute;
      padding: 0;
      transform: translateX(220px);
      top: 0;
      left: 0;
      height: 100%;

      &:after {
        content: '';
        background: $color-light-peach;
        background: -moz-linear-gradient(
          180deg,
          rgba($color-light-peach, 0) 0%,
          rgba($color-light-peach, 1) 100%
        );
        background: -webkit-linear-gradient(
          180deg,
          rgba($color-light-peach, 0) 0%,
          rgba($color-light-peach, 1) 100%
        );
        background: linear-gradient(
          180deg,
          rgba($color-light-peach, 0) 0%,
          rgba($color-light-peach, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc6a9",endColorstr="#ffb2a3",GradientType=1);
        height: 10vh;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
      }
    }

    &__inner {
      position: relative;
      height: 100%;
      overflow: hidden;
      overflow-y: scroll;
      padding-right: gap(1);
      width: 280px;

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-grey-dark;
        border-radius: 3px;
      }

      ul {
        padding-bottom: gap(5);
      }
    }
  }

  &__scroll-container {
    height: 100%;
    position: relative;
  }

  &__marker__svg,
  &__marker__number {
    transition: transform 0.3s ease-out;
  }

  &__marker {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    // transform: `translate(${-markerSize / 2}px,${-markerSize}px)`

    &--active {
      z-index: $z-index-mapbox-selected-pin;
    }
  }

  &__filter {
    margin-bottom: gap(1);

    & > .button {
      width: 100%;

      @include large {
        width: auto;
      }

      &:not(:last-child) {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      @include type-sm;
      display: inline-flex;
      align-items: center;
      align-self: flex-start;
      padding: 0 gap(3);
      border: 1px solid $color-black;
      height: $filter-height;
      border-radius: calc($filter-height / 2);
      cursor: pointer;
      transition: all 0.5s ease-in-out;

      @include medium {
        @include type-md;
      }

      &:hover,
      &--active {
        background-color: $color-black;
        color: $color-white;
      }
    }
  }

  &__result {
    @include type-sm;
    display: flex;
    align-items: center;
    width: max-content;
    max-width: 100%;

    @include medium {
      @include type-md;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &--active,
    &--active:hover {
      color: $color-black;
      text-decoration: underline;
    }

    &:not(:last-child) {
      margin-bottom: gap(1);
    }

    &__number {
      @include type-xxs;
      align-self: flex-start;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
      background-color: $color-black;
      height: 25px;
      width: 25px;
      min-width: 25px;
      margin-right: gap(1);
      border-radius: 20px;
      font-family: $font-family-bold;
    }
  }

  &__selected-pin {
    position: absolute;
    bottom: gap(2);
    right: gap(2);
    padding: gap(2);
    color: $color-white;
    background: rgba($color-white, 0.8);
    backdrop-filter: blur(10px);
    z-index: $z-index-mapbox-pin-detail-overlay;
    color: $color-black;
    border: 1px solid $color-black;
    border-radius: $border-radius-sm;
    max-width: calc(100% - #{gap(6)} - #{$music-player-size-sm});
    transition: background-color $ease-link;

    @include large {
      @include type-md;
      bottom: gap(3);
      right: gap(3);
      padding: gap(3);
      border-radius: $border-radius-md;
    }

    &:hover {
      cursor: pointer;
      background: $color-white;

      .mapbox__selected-pin__link-text {
        text-decoration: underline;
      }
    }

    p {
      font-size: $font-size-sm;
      margin: 0;

      @include x-large {
        font-size: $font-size-md;
      }
    }

    &__title {
      font-family: $font-family-bold;
      margin: 0;
    }

    &__link-text {
      // font-size: $font-size-xs !important;
      margin-top: 0.5em !important;
      color: $color-green-darker;
    }
  }

  &__controls-wrapper {
    right: gap(2);
    top: gap(2);
    z-index: 2;

    @include medium {
      right: gap(3);
      top: gap(3);
    }
  }
}

// overrides from mapbox classes
.mapboxgl-ctrl.mapboxgl-ctrl-attrib * {
  font-size: $font-size-xxs;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: gap(2) gap(2) 0 0;
  background: transparent;
  box-shadow: none;
}

// controls
.mapboxgl-ctrl.mapboxgl-ctrl-group {
  background: transparent;
  box-shadow: none;
  z-index: $z-index-mapbox-controls;

  button {
    background: transparent;
    height: $mapbox-controls-size-sm;
    width: $mapbox-controls-size-sm;
    border-radius: calc(#{$mapbox-controls-size-sm} / 2);
    margin-bottom: 10px;
    position: relative;
    transition: all 0.3s ease;

    @include large {
      height: $mapbox-controls-size-lg;
      width: $mapbox-controls-size-lg;
      border-radius: calc(#{$mapbox-controls-size-lg} / 2);
    }

    & + button {
      border: none;
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      background: $color-white;
      height: 100%;
      width: 100%;
      border-radius: calc(#{$mapbox-controls-size-sm} / 2);
      transition: transform 0.3s ease-in-out;
      box-shadow: $shadow;
      top: 0;
      z-index: -1;

      @include large {
        border-radius: calc(#{$mapbox-controls-size-lg} / 2);
      }
    }

    // &:focus:first-child,
    // &:focus:last-child {
    // 	border-radius: calc(#{$control-button-size} / 2);
    // }

    // &:not(:disabled):hover {
    // 	background: $color-white;
    // 	box-shadow: $shadow;
    // }

    &.mapboxgl-ctrl-compass {
      display: none;
    }
  }
}
