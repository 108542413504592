@import '../variables.module.scss';
@import '../mixins.scss';

.benefits-section {
  padding: gap(6) 0;

  &--border {
    border-top: 1px solid $color-black;

    &:last-of-type {
      border-bottom: 1px solid $color-black;
    }
  }
}

.benefits {
  margin-top: gap(4);
  display: grid;
  gap: gap(4);
  grid-template-columns: 1fr 1fr;

  @include medium {
    margin-top: gap(6);
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.benefit {
  height: 100%;

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__headline {
    @include font-sm;
    font-family: $font-family-bold;
    padding: 0 gap(5) 0 0;

    @include medium {
      padding: 0 gap(6) 0 0;
    }

    @include x-large {
      padding: 0 gap(10) 0 0;
    }

    &--lg {
      @include font-md;
    }
  }
}
