@import '../variables.module.scss';

.linktree {
  &__link {
    margin-bottom: gap(1);

    &:not(:last-child) {
      margin-bottom: 0;
    }

    a {
      @include type-sm;
      text-decoration: underline;

      @include large {
        @include type-md;
      }
    }
  }
}
