@import '../variables.module.scss';
@import '../mixins.scss';

.collapsable {
  display: flex;
  justify-content: center;
}
.collapsable:not(:last-child) {
  margin-bottom: 1em;
}
.collapsable--active {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  width: 100%;
}
.collapsable--active .collapsable__body {
  display: flex;
}
.collapsable--active .collapsable__btn img {
  transform: rotate(180deg);
}
.collapsable__head {
  border: 1px solid #25262e;
  height: 50px;
  border-radius: calc(50px / 2);
  padding: 0 1em;
  max-width: 1000px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.collapsable__head__text {
  display: flex;
  justify-content: space-between;
  flex: 0.9;
}
.collapsable__head__text p {
  margin: 0;
}
.collapsable__btn {
  flex: 0.1;
  display: flex;
  justify-content: flex-end;
}
.collapsable__body {
  padding: 1em;
  background-color: #fff;
  display: flex;
  border-radius: calc(50px / 2);
  margin: 10px 0 10px 0;
  width: 100%;
  max-width: 1000px;
  display: none;
}
.collapsable__body__inner {
  flex: 1;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .collapsable__body__inner {
    flex: 0.9;
  }
}
.collapsable__list {
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
}
@media only screen and (min-width: 768px) {
  .collapsable__list {
    flex-direction: row;
  }
}
.collapsable__list:not(:nth-child(2)) {
  border-top: 1px solid rgba(37, 38, 46, 0.15);
}
@media only screen and (min-width: 768px) {
  .collapsable__list:not(:nth-child(2)) {
    border: none;
  }
}
.collapsable__list:first-child {
  display: none;
}
@media only screen and (min-width: 768px) {
  .collapsable__list:first-child {
    display: flex;
  }
}
.collapsable__list:first-child li {
  font-family: 'SuisseIntl Bold', sans-serif;
}
.collapsable__list__item {
  list-style: none;
  padding: 10px 0;
}
@media only screen and (min-width: 768px) {
  .collapsable__list__item {
    width: 33.3333333333%;
  }
}
.collapsable__list__item:not(:last-child) {
  padding: 1em 0 0 0;
}
@media only screen and (min-width: 768px) {
  .collapsable__list__item:not(:last-child) {
    padding: 10px 0;
  }
}
