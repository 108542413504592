.vimeo-video {
  padding: 56.25% 0 0 0;
  position: relative;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.vimeo-video-title {
  margin: gap(2) 0 gap(6);
}
