@import '../../styles/variables.module.scss';
@import '../../styles/mixins.scss';

$form-height: 50px;

.form {
  position: relative;

  input {
    width: 100%;
    appearance: none;
    background: none;
    border: 1px solid $color-black;
    height: $form-height;
    border-radius: calc(#{$form-height} / 2);
    padding: 0 gap(2);
    transition: border-color 0.4s ease;
    will-change: border-color;
  }

  select {
    display: none;
  }

  &__container {
    display: flex;
    flex: wrap;
    flex-direction: column;
  }

  &__col {
    position: relative;
    width: 100%;
    margin-bottom: gap(2);
  }

  &__label {
    @include type-sm;
    font-family: $font-family-bold;
    position: absolute;
    left: gap(2);
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  &__input,
  &__select {
    @include type-sm;
    font-family: $font-family-bold;

    &:focus {
      border-color: $color-white;
    }

    &--error {
      border-color: $color-error !important;
    }
  }

  &__input {
    &:focus + .form__label,
    &:valid + .form__label {
      display: none;
    }
  }

  &__button {
    font-family: $font-family-bold;
    width: 100%;
    height: $form-height;
    border-radius: calc(#{$form-height} / 2);
    border: none;
    background: $color-black;
    color: $color-white;
    cursor: pointer;
  }

  &__text-container {
    @include medium {
      padding-left: calc(40px - var(--bs-gutter-x));
    }
  }

  &__select {
    cursor: pointer;
    width: 100%;
    appearance: none;
    background: none;
    border: 1px solid $color-black;
    height: $form-height;
    border-radius: calc(#{$form-height} / 2);
    padding: 0 gap(2);
    transition: border-color 0.4s ease;
    will-change: border-color;
    outline: none;

    svg {
      position: absolute;
      right: 16px;
      top: calc(50% + 1px);
      transform: translateY(-50%);
    }

    &--active {
      svg {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__options {
    position: absolute;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    z-index: 99;
    border-radius: 20px;
    overflow: hidden;
    pointer-events: all;

    li {
      @include type-sm;
      border: none;
      color: $color-black;
      padding: 0.5em 1em;

      @include medium {
        padding: 0.5em 1em;
      }

      &:first-child {
        padding: 2.5em 1em 1em;
        font-family: $font-family-bold;

        @include medium {
          padding: 2em 1em 1em;
        }
      }

      &:last-child {
        margin-bottom: 1em;
      }

      &:not(:first-child) {
        cursor: pointer;
      }

      &:not(:first-child):hover {
        background-color: $color-black;
        color: $color-white;
      }
    }
  }

  &__response {
    margin-top: gap(2);
    display: flex;
    justify-content: center;
    flex-direction: column;

    a {
      text-decoration: underline;
    }

    &__icon {
      width: 50px;
      height: 50px;
      margin-bottom: gap(2);

      @include medium {
        margin-bottom: gap(3);
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__title {
      margin-bottom: gap(3);
    }
  }
}

.collage {
  margin-top: gap(4);
  text-align: center;

  &--flex-living {
    margin-top: -20px;

    @include medium {
      margin-top: -140px;
    }
  }
}

.contact-informations {
  margin: gap(3) 0;

  @include medium {
    margin: 0 0 gap(3) 0;
  }

  p {
    margin: 0;
  }
}
